import React, { useState } from "react";
import telugu from "./telugu.json";
import english from "./english.json";
import tamil from "./tamil.json";
import hindi from "./hindi.json";
import kannada from "./kannada.json";
import malayalam from "./malayalam.json";
import CopyClipboard from './CopyClipboard.png';
import LeftArrow from './left-arrow.png';
import RightArrow from './right-arrow.png';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Col } from "react-bootstrap";
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const languageMap = {
  "English": english,
  "Hindi": hindi,
  "Kannada": kannada,
  "Malayalam": malayalam,
  "Tamil": tamil,
  "Telugu": telugu
};

const mapBible = (bible) => {
  const bibleMap = {};
  if (bible !== undefined) {
    bible.books.forEach((book) => {
      bibleMap[book.bnumber] = {};
      book.chapters.forEach((chapter) => {
        bibleMap[book.bnumber][chapter.cnumber] = {};
        chapter.verses.forEach((verse) => {
          bibleMap[book.bnumber][chapter.cnumber][verse.vnumber] = verse.text;
        });
      });
    });
  }
  return bibleMap;
};

const getVerse = (bibleMap, bookName, chapterNumber, verseNumber) => {
  if (bibleMap[bookName] && bibleMap[bookName][chapterNumber] && bibleMap[bookName][chapterNumber][verseNumber]) {
    return bibleMap[bookName][chapterNumber][verseNumber];
  }
  return '';
};

function Bible() {

  const [ntBooksVisible, setNtBooksVisible] = useState(false);
  const [otBooksVisible, setOtBooksVisible] = useState(false);
  const [CHSVisible, setCHSVisible] = useState(false);

  const [fontSize, setFontSize] = useState(17);
  const increaseFontSize = () => {
    setFontSize((prevFontSize) => prevFontSize + 2);
  };
  const decreaseFontSize = () => {
    setFontSize((prevFontSize) => (prevFontSize > 10 ? prevFontSize - 2 : prevFontSize));
  };


  const [books, setBooks] = useState(telugu.books);
  const [bookNumber, setBookNumber] = useState(1);
  const [bookName, setBookName] = useState("ఆదికాండము");
  const [chapters, setChapters] = useState(telugu.books[0].chapters);
  const [chapter, setChapter] = useState(chapters[0]);
  const [selectedVerses, setSelectedVerses] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [languageName, setLanguageName] = useState("Telugu");
  const [compareLanguageName, setCompareLanguageName] = useState("English");

  const [bible, setBible] = useState(languageMap["Telugu"]);
  const [compareBible, setCompareBible] = useState(languageMap["English"]);

  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;
    const selectedBible = languageMap[selectedLanguage] || telugu;
    setBible(selectedBible);
    setBooks(selectedBible.books);
    setChapters(selectedBible.books[0].chapters);
    setChapter(selectedBible.books[0].chapters[0]);
    setBookNumber(selectedBible.books[0].bnumber);
    setBookName(selectedBible.books[0].bname);
    setSelectedVerses([]);
    setSearchResult([]);
    setSearchQuery("");
    setLanguageName(selectedLanguage);
  };

  const handleCompareLanguageChange = (event) => {
    const selectedLanguage = event.target.value || "English";
    setCompareBible(languageMap[selectedLanguage] || languageMap["English"]);
    setCompareLanguageName(selectedLanguage);
  };

  const handleBookChange = (event) => {
    console.log(event.target);
    console.log('event', event.target.value);
    const selectedBook = books.find((book) => book.bname === event.target.value);
    console.log('selectedBook', selectedBook);
    setChapters(selectedBook.chapters);
    setChapter(selectedBook.chapters[0]);
    setBookNumber(selectedBook.bnumber);
    setBookName(selectedBook.bname);
    setSelectedVerses([]);
    setSearchResult([]);
    setSearchQuery('');
  };

  const handleBookChange1 = (book) => {
    const selectedBook = books.find((b) => b.bnumber === book.bnumber);
    console.log('selectedBook', selectedBook);
    setChapters(selectedBook.chapters);
    setChapter(selectedBook.chapters[0]);
    setBookNumber(selectedBook.bnumber);
    setBookName(selectedBook.bname);
    setSelectedVerses([]);
    setSearchResult([]);
    setSearchQuery('');
    setNtBooksVisible(!ntBooksVisible);
  };

  const handleBookChange2 = (book) => {
    const selectedBook = books.find((b) => b.bnumber === book.bnumber);
    console.log('selectedBook', selectedBook);
    setChapters(selectedBook.chapters);
    setChapter(selectedBook.chapters[0]);
    setBookNumber(selectedBook.bnumber);
    setBookName(selectedBook.bname);
    setSelectedVerses([]);
    setSearchResult([]);
    setSearchQuery('');
    setOtBooksVisible(!otBooksVisible);
  };


  const handleChapterChange = (event) => {
    const selectedChapter = chapters.find((chap) => chap.cnumber === parseInt(event.target.value, 10));
    setChapter(selectedChapter);
    setSelectedVerses([]);
    setSearchResult([]);
    setSearchQuery('');
  };

  const handleChapterChange1 = (chap) => {
    const selectedChapter = chapters.find((c) => c.cnumber === chap.cnumber);
    setChapter(selectedChapter);
    setSelectedVerses([]);
    setSearchResult([]);
    setSearchQuery('');
    setCHSVisible(!CHSVisible);
  };


  const handleVerseSelection = (verse) => {
    setSelectedVerses((prevVerses) => prevVerses.includes(verse) ? prevVerses.filter((v) => v !== verse) : [...prevVerses, verse]);
  };

  const copySelectedVerses = () => {
    const versesText = `${bookName} ${chapter.cnumber}:${selectedVerses.map((verse) => `${verse.vnumber}. ${verse.text} ${compareLanguageName && Object.keys(compareBibleMap).length > 0 && `\n${verse.vnumber}. ${getVerse(compareBibleMap, bookNumber, chapter.cnumber, verse.vnumber)}`}`).join('\n')}`;
    navigator.clipboard.writeText(`${versesText}\n\nhttps://fulltruthspreaders.com/`);
  };

  const copyVerse = (result) => {
    const compareVerse = compareLanguageName
      ? `\n${result.verseNumber}. ${getVerse(compareBibleMap, result.booknumber, result.chapterNumber, result.verseNumber)}`
      : "";
    navigator.clipboard.writeText(`${result.bookname} ${result.chapterNumber}:${result.verseNumber} ${result.verseText}${compareVerse}\n\nhttps://fulltruthspreaders.com/`);
  };

  const handleSearch = () => {
    const results = [];
    if (searchQuery && searchQuery.length > 2) {
      const words = searchQuery.toLowerCase().split(' ');
      books.forEach((book) => {
        book.chapters.forEach((chap) => {
          chap.verses.forEach((verse) => {
            if (words.every(word => verse.text.toLowerCase().includes(word))) {
              results.push({
                bookname: book.bname,
                booknumber: book.bnumber,
                chapterNumber: chap.cnumber,
                verseText: verse.text,
                verseNumber: verse.vnumber
              });
            }
          });
        });
      });
    }
    setSearchResult(results);
  };

  const highlightText = (text, query) => {
    if (!query) return text;
    const words = query.toLowerCase().split(' ');
    const regex = new RegExp(`(${words.join('|')})`, 'gi');
    const parts = text.split(regex);
    return parts.map((part, index) =>
      words.includes(part.toLowerCase()) ? <span key={index} style={{ backgroundColor: 'yellow' }}>{part}</span> : part
    );
  };

  const navigateTo = (result) => {
    setBookName(result.bookname);
    setBookNumber(result.booknumber);
    const selectedBook = books.find((book) => book.bname === result.bookname);
    setChapters(selectedBook.chapters);
    const selectedChapter = selectedBook.chapters.find((chap) => chap.cnumber === result.chapterNumber);
    setChapter(selectedChapter);
  };

  const compareBibleMap = mapBible(languageMap[compareLanguageName]);

  return (
    <>
      <Navbar expand={false} className="mb-4 App-header">
        <Container fluid>
          <Row className="w-100 align-items-center">
            {/* Left side: Toggle and Bible text */}
            <Col xs="auto" md="2" lg="4" className="d-flex align-items-center toggle-col">
              <Navbar.Toggle aria-controls="offcanvasNavbar" className="me-3" />
              <span className="text-white fw-bold">Bible</span>
            </Col>

            {/* Center: Logo with text */}
            <Col xs="auto" md="6" lg="4" className="d-flex justify-content-center logo-col">
              <h1 className="App-title">
                <a href="/">Full Truth Spreaders</a>
              </h1>
            </Col>

            {/* Right side: Font size controls and search bar */}
            <Col xs="auto" md="4" lg="4" sm="12" className="d-flex align-items-center justify-content-end font-search-col">
              <div className="custom-font-size me-3">
                <div className="font-size-controls d-flex">
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id="tooltip-bottom">
                        Increase font size
                      </Tooltip>
                    }
                  >
                    <Button className="btn btn-light fw-medium py-1 px-3 me-3"
                      onClick={increaseFontSize} >+</Button>
                  </OverlayTrigger>

                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id="tooltip-bottom">
                        Decrease font size
                      </Tooltip>
                    }
                  >
                    <Button className="btn btn-light fw-medium py-1 px-3"
                      onClick={decreaseFontSize} >-</Button>
                  </OverlayTrigger>
                </div>
              </div>
              <div className="right_search_bar">
                <div className="form-group position-relative w-100">
                  <input
                    className="form-control h-55 bg-body-bg border-0 text-dark rounded-pill"
                    onKeyDown={handleSearch}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Search min 3 chars..."
                    type="text"
                    value={searchQuery}
                  />
                  <button
                    className="position-absolute top-50 end-0 translate-middle-y bg-primary border-0 text-center text-white rounded-pill me-3 fw-semibold"
                    onClick={handleSearch}
                  >
                    Search
                  </button>
                </div>
              </div>
            </Col>

            {/* Right side: Font size controls and search bar */}
            <Col xs="auto" md="4" lg="4" sm="12" className="d-flex align-items-center justify-content-end font-search-col font-size-res-col">
              <div className="custom-font-size me-3">
                <div className="font-size-controls d-flex">
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id="tooltip-bottom">
                        Increase font size
                      </Tooltip>
                    }
                  >
                    <Button className="btn btn-light fw-medium py-1 px-3 me-3"
                      onClick={increaseFontSize} >+</Button>
                  </OverlayTrigger>

                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id="tooltip-bottom">
                        Decrease font size
                      </Tooltip>
                    }
                  >
                    <Button className="btn btn-light fw-medium py-1 px-3"
                      onClick={decreaseFontSize} >-</Button>
                  </OverlayTrigger>
                </div>
              </div>
            </Col>

            <Col xs="auto" md="4" lg="4" sm="12" className="d-flex align-items-center justify-content-end font-search-col search_col-res">
              <div className="right_search_bar">
                <div className="form-group position-relative w-100">
                  <input
                    className="form-control h-55 bg-body-bg border-0 text-dark rounded-pill"
                    onKeyDown={handleSearch}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Search min 3 chars..."
                    type="text"
                    value={searchQuery}
                  />
                  <button
                    className="position-absolute top-50 end-0 translate-middle-y bg-primary border-0 text-center text-white rounded-pill me-3 fw-semibold"
                    onClick={handleSearch}
                  >
                    Search
                  </button>
                </div>
              </div>
            </Col>
          </Row>

          {/* Left-side Offcanvas */}
          <Navbar.Offcanvas id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel" placement="start">
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id="offcanvasNavbarLabel">Bible</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div className="books_chapters_container">

                <Accordion>
                  {/* Old Testament */}
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Old Testament</Accordion.Header>
                    <Accordion.Body>
                      <Accordion>
                        {books.slice(0, 39).map((book, index) => (
                          <Accordion.Item eventKey={`old-${index}`} key={book.bnumber}>
                            <Accordion.Header onClick={() => handleBookChange1(book)}>
                              {book.bname}
                            </Accordion.Header>
                            <Accordion.Body>
                              <ul>
                                {book.chapters.map((chap, chapIndex) => (
                                  <li key={chap.cnumber} className="fw-medium" onClick={() => handleChapterChange1(chap)}>
                                    {chap.cnumber}
                                  </li>
                                ))}
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                        ))}
                      </Accordion>
                    </Accordion.Body>
                  </Accordion.Item>

                  {/* New Testament */}
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>New Testament</Accordion.Header>
                    <Accordion.Body>
                      <Accordion>
                        {books.slice(39).map((book, index) => (
                          <Accordion.Item eventKey={`new-${index}`} key={book.bnumber}>
                            <Accordion.Header onClick={() => handleBookChange2(book)}>
                              {book.bname}
                            </Accordion.Header>
                            <Accordion.Body>
                              <ul>
                                {book.chapters.map((chap, chapIndex) => (
                                  <li key={chap.cnumber} className="fw-medium" onClick={() => handleChapterChange1(chap)}>
                                    {chap.cnumber}
                                  </li>
                                ))}
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                        ))}
                      </Accordion>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </Offcanvas.Body>
          </Navbar.Offcanvas>

        </Container>
      </Navbar>

      <Container fluid className="position-relative" style={{ fontSize: `${fontSize}px` }}>

        <Row>
          {/* Left Column Starts */}
          <Col xs="auto" md="6" lg="6" className="content_col_left">
            <Row className="table_content left_side">
              <Col className="col-lg-12 col-md-12 col-sm-12">
                <div className="card bg-white border-0 rounded-3 mb-4">
                  <div className="card-body p-4">
                    <Row className="lesson_with_buttons">
                      <Col xs="auto" md="auto" lg="3" className="select_language_col">
                        <div className="form-group position-relative">
                          <select className="form-select form-control h-55 bg-body-bg text-dark rounded-pill" value={languageName} onChange={handleLanguageChange}>
                            {Object.keys(languageMap).map((language, index) => (
                              <option key={index}>{language}</option>
                            ))}
                          </select>
                        </div>
                      </Col>
                      <Col xs="auto" md="6" lg="6" className="names_btns_col">
                        <Row className="d-flex align-items-center">
                          <Col xs="auto" md="auto" lg="auto">
                            <div className="prev_btn">
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Previous Chapter</Tooltip>}
                              >
                                <button
                                  className="btn btn-primary fw-medium text-white py-2 px-2 rounded-pill"
                                  disabled={chapter.cnumber === 1}
                                  onClick={() => setChapter(chapters[chapter.cnumber - 2])}
                                >
                                  <img width="30" height="30" src={LeftArrow} alt="Previous" />
                                </button>
                              </OverlayTrigger>
                            </div>
                          </Col>
                          <Col>
                            <div className="lesson_name">
                              <h4>{`${bookName} ${chapter.cnumber}`}</h4>
                            </div>
                          </Col>
                          <Col xs="auto" md="auto" lg="auto">
                            <div className="next_btn">
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Next Chapter</Tooltip>}
                              >
                                <button
                                  className="btn btn-primary fw-medium text-white py-2 px-2 rounded-pill"
                                  disabled={chapter.cnumber === chapters.length}
                                  onClick={() => setChapter(chapters[chapter.cnumber])}
                                >
                                  <img width="30" height="30" src={RightArrow} alt="Next" />
                                </button>
                              </OverlayTrigger>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs="auto" md="auto" lg="3" className="d-flex justify-content-end copy_col">
                        <ul key={0}>
                          <li>
                            <button className="btn btn-primary fw-medium text-white py-2 px-4 rounded-pill" disabled={selectedVerses.length === 0} onClick={copySelectedVerses}>
                              <img width="10" height="10" src={CopyClipboard} alt="logo" /> Copy
                            </button>
                          </li>
                        </ul>
                      </Col>
                    </Row>

                    <table>
                      <tbody>
                        {chapter?.verses?.map((verse, index) => (
                          <tr key={index} className="border-bottom pb-4 mb-4 d-flex justify-content-between" onClick={() => handleVerseSelection(verse)}>
                            <td className="d-flex verse-container"><span>{verse.vnumber}.&nbsp;</span>{verse.text}</td>
                            <td>
                              <div className="form-group">
                                <div className="form-check">
                                  <input className="form-check-input" checked={selectedVerses.includes(verse)} onChange={() => handleVerseSelection(verse)} type='checkbox' onClick={(e) => e.stopPropagation()} />
                                </div>
                              </div>
                            </td>
                          </tr>
                        )) || <tr><td>No verses available</td></tr>}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          {/* Left Column Ends */}

          {/* Right Column Starts */}
          <Col xs="auto" md="6" lg="6" className="content_col_right">
            {searchResult.length === 0 && (
              <Row className="table_content right-side">
                <Col className="col-lg-12 col-md-12 col-sm-12">
                  <div className="card bg-white border-0 rounded-3 mb-4">
                    <div className="card-body p-4">
                      <Row className="lesson_with_buttons">
                        <Col xs="auto" md="6" lg="6">
                          <div className="form-group position-relative">
                            <select className="form-select form-control h-55 bg-body-bg text-dark rounded-pill" value={compareLanguageName} onChange={handleCompareLanguageChange}>
                              <option value="">Compare with other Language</option>
                              {Object.keys(languageMap).filter(language => language !== languageName).map((language, index) => (
                                <option key={index}>{language}</option>
                              ))}
                            </select>
                          </div>
                        </Col>

                        <Col xs="auto" md="6" lg="6" className="d-flex justify-content-end">
                          <ul key={0} >
                            <li>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                      <table>
                        <tbody>
                          {compareBible?.books?.[bookNumber - 1]?.chapters?.[chapter.cnumber - 1]?.verses?.map((verse, index) => (
                            <tr key={index} className="border-bottom pb-4 mb-4 d-flex justify-content-between">
                              <td className="d-flex verse-container"><span>{verse.vnumber}.&nbsp;</span>{verse.text}</td>
                            </tr>
                          )) || <tr><td>No verses available</td></tr>}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Col>
              </Row>
            )}

            {searchResult.length > 0 && (
              <Row className="table_search_content">
                <Col className="col-lg-12 col-md-12 col-sm-12">
                  <div className="card bg-white border-0 rounded-3 mb-4">
                    <div className="card-body p-4">
                      <h4 className="mb-4">{`Search Result (${searchResult.length})`}</h4>
                      <table>
                        <tbody>
                          {searchResult.map((result, index) => (
                            <tr key={index} className="border-bottom pb-4 mb-4 d-flex justify-content-between">
                              <td className="d-flex verse-container">
                                <div>
                                  <span>{index + 1}.</span> <span><button className="btn bg-danger bg-opacity-10 fw-medium text-danger py-2 px-4 border-0" onClick={() => navigateTo(result)}>
                                    {result.bookname} {result.chapterNumber}:{result.verseNumber}
                                  </button> <span>{highlightText(result.verseText, searchQuery)}</span></span>
                                </div>
                              </td>
                              <td>
                                <button onClick={() => copyVerse(result)} className="btn btn-primary fw-medium text-white py-2 px-2 rounded-pill search_copy_btn">
                                  <img src={CopyClipboard} alt="logo" />
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Col>
              </Row>
            )}
          </Col>
          {/* Right Column Ends */}
        </Row>

        <div className="prev_next_btn_bottom">
          <Row>
            <Col xs="auto" md="6" lg="6">
              <div className="prev_btn">
                <button className="btn btn-primary fw-medium text-white py-2 px-4 rounded-pill" disabled={chapter.cnumber === 1} onClick={() => setChapter(chapters[chapter.cnumber - 2])}>Previous Chapter</button>
              </div>
            </Col>
            <Col xs="auto" md="6" lg="6" className="m-l-auto">
              <div className="next_btn">
                <button className="btn btn-primary fw-medium text-white py-2 px-4 rounded-pill" disabled={chapter.cnumber === chapters.length} onClick={() => setChapter(chapters[chapter.cnumber])}>Next Chapter</button>
              </div>
            </Col>
          </Row>
        </div>

      </Container>
    </>
  );
}

export default Bible;
