import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Col } from "react-bootstrap";
import './App.css';
import bible from './Bible';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

class App extends Component {
  render() {
    return (
      <Router basename={process.env.PUBLIC_URL}>
        <div className="App">
          
          <Switch>
            <Route exact path="/" render={() => (
              <Redirect to="/bible" />
            )} />
            <Route exact path="/bible" component={bible} />
          </Switch>
          <footer>
            <Container fluid>
              <Row>
                <Col lg="6" md="7" sm="12">
                  <div className="coppyright">
                    <p>© 2024 <a href="/">Full Truth Spreaders</a> | All Rights Reserved.</p>
                  </div>
                </Col>
                <Col lg="6" md="5" sm="12">
                  <div className="coppyright right-side">
                    <p>Designed by <a href="https://www.honeysoftsolutions.in/" target="_blank">Honey Soft Solutions</a></p>
                  </div>
                </Col>
              </Row>
            </Container>
          </footer>
        </div>
      </Router>
    );
  }
}

export default App;

